import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { useTranslation } from 'next-i18next';

import { CHANNEL, notFoundSlugs } from '@boss/constants/b2b-b2c';
import { getContentPage } from '@boss/contentful-client/b2b-b2c';
import type { ContentPageFields, LOCALE_CODE } from '@boss/types/b2b-b2c';

import { defaultLocale } from '../../../config/locales.config';
import { SEO } from '../components';
import { PageTypes } from '../constants';
import NotFoundPage from '../containers/notFoundPage';
import Layout from '../layout/default';
import getServerSideTranslations from '../utils/getServerSideTranslations';
import { mapNextToContentfulLocale } from '../utils/localeMapper';
import { getMappedNavigation } from '../utils/navigation';

export const getStaticProps = async ({
  preview,
  locale,
}: GetStaticPropsContext<{ slug: string; locale: LOCALE_CODE }>) => {
  if (!locale || locale === 'default' || !CHANNEL) {
    return {
      props: {
        pageData: null,
        preview: !!preview,
        navigation: null,
      },
    };
  }

  const contentfulLocale = mapNextToContentfulLocale(locale);

  const [pageData, navigation] = await Promise.all([
    getContentPage<ContentPageFields>({
      preview: !!preview,
      slug: '404',
      locale: contentfulLocale,
      channel: CHANNEL,
      defaultLocale,
    }),
    getMappedNavigation(contentfulLocale, !!preview),
  ]);

  return {
    props: {
      pageType: PageTypes.NOT_FOUND,
      preview: !!preview,
      pageData,
      navigation,
      ...(await getServerSideTranslations(locale, ['common', 'forms', '404'])),
    },
  };
};

export const Index = ({ pageData, preview, navigation }: InferGetStaticPropsType<typeof getStaticProps>) => {
  const { t } = useTranslation('404');
  const crumbles = [{ href: '/', label: '404' }];
  const localizedSlugs = notFoundSlugs;
  const pageTitle = t('meta.title');

  return (
    <SEO
      breadcrumbs={crumbles}
      description={pageData?.metaDescription ?? pageTitle}
      imageSrc={pageData?.ogImage?.image.file.url}
      localizedSlugs={localizedSlugs}
      navigation={navigation}
      noFollow={pageData?.noFollow}
      noIndex={pageData?.noIndex}
      title={pageData?.pageTitle ?? pageTitle}
    >
      <Layout crumbles={crumbles} localizedSlugs={localizedSlugs} navigation={navigation} preview={preview}>
        <NotFoundPage cmsData={pageData} />
      </Layout>
    </SEO>
  );
};

export default Index;
